@charset "utf-8";
/*
Template Name: Gomag
Author: <a href="http://www.os-templates.com/">OS Templates</a>
Author URI: http://www.os-templates.com/
Licence: Free to use under our free template licence terms
Licence URI: http://www.os-templates.com/template-terms
File: Layout CSS
*/

@import url("font-awesome.min.css");
@import url("framework.css");

/* Rows
--------------------------------------------------------------------------------------------------------------- */
.row1{z-index:1;}/* Required for the drop shadow - do not delete, unless the drop shadow isn't wanted */
.row2, .row2 a{}
.row3, .row3 a{}
.row4, .row4 a{}
.row5, .row5 a{}


/* Header
--------------------------------------------------------------------------------------------------------------- */
#header{}

#header #logo{margin:30px 0 0 0;}
#header #logo h1{margin:0; padding:0; font-size:22px;}


/* Page Intro
--------------------------------------------------------------------------------------------------------------- */
#pageintro{padding:250px 0;}

#pageintro > div{display:block; max-width:60%;}
#pageintro > div *{line-height:normal;}
#pageintro .heading{margin:0 0 30px 0; padding:0; font-size:3rem;}
#pageintro p{margin:0; font-size:14px; line-height:2rem;}
#pageintro footer{margin-top:30px;}


/* Content Area
--------------------------------------------------------------------------------------------------------------- */
.container{padding:80px 0;}
.btmpad-none{padding-bottom:0;}

/* Content */
.container .content{}

.sectiontitle{margin-bottom:80px;}
.sectiontitle *{margin:0;}


.sc-menue{
	overflow: auto;
	white-space: nowrap;
}

.sc-menue article{
	display: inline-block;

	text-align: center;
	padding: 14px;
	text-decoration: none;
}

.infoboxes{}
.infoboxes li {padding:50px 0 0 0;  text-align:center;}
.infoboxes li :last-child{margin-bottom:0;}/* Used when elements stack in small viewports */
.infoboxes li *{margin:0; padding:0; line-height:1.6;}
.infoboxes article {
	margin-right: 20px;
	margin-bottom:10px;
	padding-top: 10px;

	height : 250px;
	width : 220px;
}

.infoboxes article .fa{font-size:18px;}

.product{
	height : 180px;
	width : 200px;
	
}
.plist{
	margin-left: 11%;
}

.logos a img{opacity:.5;}
.logos a:hover img{opacity:1;}

/* Comments */
#comments ul{margin:0 0 40px 0; padding:0; list-style:none;}
#comments li{margin:0 0 10px 0; padding:15px;}
#comments .avatar{float:right; margin:0 0 10px 10px; padding:3px; border:1px solid;}
#comments address{font-weight:bold;}
#comments time{font-size:smaller;}
#comments .comcont{display:block; margin:0; padding:0;}
#comments .comcont p{margin:10px 5px 10px 0; padding:0;}

#comments form{display:block; width:100%;}
#comments input, #comments textarea{width:100%; padding:10px; border:1px solid;}
#comments textarea{overflow:auto;}
#comments div{margin-bottom:15px;}
#comments input[type="submit"], #comments input[type="reset"]{display:inline-block; width:auto; min-width:150px; margin:0; padding:8px 5px; cursor:pointer;}

/* Sidebar */
.container .sidebar{}

.sidebar .sdb_holder{margin-bottom:50px;}
.sidebar .sdb_holder:last-child{margin-bottom:0;}


/* Footer
--------------------------------------------------------------------------------------------------------------- */
#footer{padding:80px 0;}

#footer .heading{margin-bottom:50px; font-size:14px;}

#footer nav{margin-bottom:30px; text-transform:uppercase;}
#footer nav ul{}
#footer nav ul li{display:inline-block; margin-right:20px;}
#footer nav ul li:last-child{margin-right:0;}

#footer .linklist li{display:block; margin-bottom:15px; padding:0 0 15px 0; border-bottom:1px solid;}
#footer .linklist li:last-child{margin:0; padding:0; border:none;}
#footer .linklist li::before, #footer .linklist li::after{display:table; content:"";}
#footer .linklist li, #footer .linklist li::after{clear:both;}

#footer .contact{}
#footer .contact.linklist li, #footer .contact.linklist li:last-child{position:relative; padding-left:40px;}
#footer .contact li *{margin:0; padding:0; line-height:1.6;}
#footer .contact li i{display:block; position:absolute; top:0; left:0; width:30px; font-size:16px; text-align:center;}


/* Copyright
--------------------------------------------------------------------------------------------------------------- */
#copyright{padding:20px 0;}
#copyright *{margin:0; padding:0;}


/* Transition Fade
This gives a smooth transition to "ALL" elements used in the layout - other than the navigation form used in mobile devices
If you don't want it to fade all elements, you have to list the ones you want to be faded individually
Delete it completely to stop fading
--------------------------------------------------------------------------------------------------------------- */
*, *::before, *::after{transition:all .3s ease-in-out;}
#mainav form *{transition:none !important;}


/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */


/* Navigation
--------------------------------------------------------------------------------------------------------------- */
nav ul, nav ol{margin:0; padding:0; list-style:none;}

#mainav, #breadcrumb, .sidebar nav{line-height:normal;}
#mainav .drop::after, #mainav li li .drop::after, #breadcrumb li a::after, .sidebar nav a::after{position:absolute; font-family:"FontAwesome"; line-height:10px;}

/* Top Navigation */
#mainav{}
#mainav ul.clear{margin-top:3px;}
#mainav ul{text-transform:uppercase;}
#mainav ul ul{z-index:9999; position:absolute; width:180px; text-transform:none;}
#mainav ul ul ul{left:180px; top:0;}
#mainav li{display:inline-block; position:relative; margin:0 15px 0 0; padding:0;}
#mainav li:last-child{margin-right:0;}
#mainav li li{width:100%; margin:0;}
#mainav li a{display:block; padding:30px 0;}
#mainav li li a{border:solid; border-width:0 0 1px 0;}
#mainav .drop{padding-left:15px;}
#mainav li li a, #mainav li li .drop{display:block; margin:0; padding:10px 15px;}
#mainav .drop::after, #mainav li li .drop::after{content:"\f0d7";}
#mainav .drop::after{top:35px; left:5px;}
#mainav li li .drop::after{top:15px; left:5px;}
#mainav ul ul{visibility:hidden; opacity:0;}
#mainav ul li:hover > ul{visibility:visible; opacity:1;}
.menicon {visibility : hidden;}

#mainav form{display:none; margin:0; padding:0;}
#mainav form select, #mainav form select option{display:block; cursor:pointer; outline:none;}
#mainav form select{width:100%; padding:5px; border:1px solid;}
#mainav form select option{margin:5px; padding:0; border:none;}

/* Breadcrumb */
#breadcrumb{padding:120px 0 15px;}
#breadcrumb ul{margin:0; padding:0; list-style:none; text-transform:uppercase;}
#breadcrumb li{display:inline-block; margin:0 6px 0 0; padding:0;}
#breadcrumb li a{display:block; position:relative; margin:0; padding:0 12px 0 0; font-size:12px;}
#breadcrumb li a::after{top:3px; right:0; content:"\f101";}
#breadcrumb li:last-child a{margin:0; padding:0;}
#breadcrumb li:last-child a::after{display:none;}
#breadcrumb .heading{margin:0 0 30px 0; font-size:2rem;}

/* Sidebar Navigation */
.sidebar nav{display:block; width:100%;}
.sidebar nav li{margin:0 0 3px 0; padding:0;}
.sidebar nav a{display:block; position:relative; margin:0; padding:5px 10px 5px 15px; text-decoration:none; border:solid; border-width:0 0 1px 0;}
.sidebar nav a::after{top:9px; left:5px; content:"\f101";}
.sidebar nav ul ul a{padding-left:35px;}
.sidebar nav ul ul a::after{left:25px;}
.sidebar nav ul ul ul a{padding-left:55px;}
.sidebar nav ul ul ul a::after{left:45px;}

/* Pagination */
.pagination{display:block; width:100%; text-align:center; clear:both;}
.pagination li{display:inline-block; margin:0 2px 0 0;}
.pagination li:last-child{margin-right:0;}
.pagination a, .pagination strong{display:block; padding:8px 11px; border:1px solid; background-clip:padding-box; font-weight:normal;}

/* Back to Top */
#backtotop{z-index:999; display:inline-block; position:fixed; visibility:hidden; bottom:20px; right:20px; width:36px; height:36px; line-height:36px; font-size:16px; text-align:center; opacity:.2;}
#backtotop i{display:block; width:100%; height:100%; line-height:inherit;}
#backtotop.visible{visibility:visible; opacity:.5;}
#backtotop:hover{opacity:1;}


/* Tables
--------------------------------------------------------------------------------------------------------------- */
table, th, td{border:1px solid; border-collapse:collapse; vertical-align:top;}
table, th{table-layout:auto;}
table{width:100%; margin-bottom:15px;}
th, td{padding:5px 8px;}
td{border-width:0 1px;}


/* Gallery
--------------------------------------------------------------------------------------------------------------- */
#gallery{display:block; width:100%; margin-bottom:50px;}
#gallery figure figcaption{display:block; width:100%; clear:both;}
#gallery li{margin-bottom:30px;}


/* Font Awesome Social Icons
--------------------------------------------------------------------------------------------------------------- */
.faico{margin:0; padding:0; list-style:none;}
.faico li{display:inline-block; margin:8px 5px 0 0; padding:0; line-height:normal;}
.faico li:last-child{margin-right:0;}
.faico a{display:inline-block; width:36px; height:36px; line-height:36px; font-size:18px; text-align:center;}

.faico a{color:#FFFFFF; background-color:rgba(207,72,69,.5)/* #CF4845 */;}

.faicon-dribble:hover{background-color:#EA4C89;}
.faicon-facebook:hover{background-color:#3B5998;}
.faicon-google-plus:hover{background-color:#DB4A39;}
.faicon-linkedin:hover{background-color:#0E76A8;}
.faicon-twitter:hover{background-color:#00ACEE;}
.faicon-vk:hover{background-color:#4E658E;}


/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------ */


/* Colours
--------------------------------------------------------------------------------------------------------------- */
body{color:#CBCBCB; background-color:white;}
a{color:white;}

a:active, a:focus{background:transparent;}/* IE10 + 11 Bugfix - prevents grey background */
hr, .borderedbox{border-color:#D7D7D7;}
label span{color:#FF0000; background-color:inherit;}
input:focus, textarea:focus, *:required:focus{border-color:#CF4845;}
.overlay{color:#FFFFFF; background-color:inherit;}
.overlay::after{color:inherit; background-color:rgba(0,0,0,.55);}
.overlay.coloured::after{color:inherit; background-color:black/* #CF4845 */;}

.btn, .btn.inverse:hover{color:#FFFFFF; background-color:#CF4845; border-color:#CF4845;}
.btn:hover, .btn.inverse{color:inherit; background-color:transparent; border-color:inherit;}


/* Rows */
.row1{color:white; background-color:rgba(10,10,10,.65);}
.row1{box-shadow:0 5px 10px rgba(0,0,0,.1);}
.row2{}
.row3{color:#ffffff; background-color:#000000;}
.row4{
	background-image:url('../public/images/demo/backgrounds/70.jpg');/*10.jpg*/
	color:#CBCBCB;
	}
.row5, .row5 a{color:#CBCBCB; background-color:#191919;}


/* Header */
#header #logo a{color:inherit;}


/* Page Intro */
#pageintro{color:black;}


/* Content Area */
.infoboxes article{ background-color:rgba(190, 190, 190  ,.1);}
.infoboxes article:hover{background-color:#474747  ; color:black;}

.pname {
	color: white;
}


/* Footer */
#footer .heading{color:#FFFFFF;}
#footer hr, #footer .borderedbox, #footer .linklist li{border-color:rgba(255,255,255,.2);}
#footer nav a{color:inherit;}
#footer nav li:first-child a, #footer nav a:hover{color:#CF4845;}


/* Navigation */
#mainav li a{color:inherit;}
#mainav .active a, #mainav a:hover, #mainav li:hover > a{color:#CF4845; background-color:inherit;}
#mainav li li a, #mainav .active li a{color:#FFFFFF; background-color:rgba(207,72,69,.5)/* #CF4845 */; border-color:rgba(0,0,0,.1);}
#mainav li li:hover > a, #mainav .active .active > a{color:#FFFFFF; background-color:#CF4845;}
#mainav form select{color:#FFFFFF; background-color:#000000; border-color:rgba(255,255,255,.2);}

#breadcrumb{color:#191919;}
#breadcrumb a{color:inherit; background-color:inherit;}
#breadcrumb li:last-child a{color:#CF4845;}

.container .sidebar nav a{color:inherit; border-color:#D7D7D7;}
.container .sidebar nav a:hover{color:#CF4845;}

.pagination a, .pagination strong{border-color:#D7D7D7;}
.pagination .current *{color:#FFFFFF; background-color:#CF4845;}

#backtotop{color:#FFFFFF; background-color:#CF4845;}


/* Tables + Comments */
table, th, td, #comments .avatar, #comments input, #comments textarea{border-color:#D7D7D7;}
#comments input:focus, #comments textarea:focus, #comments *:required:focus{border-color:#CF4845;}
th{color:#FFFFFF; background-color:#373737;}
tr, #comments li, #comments input[type="submit"], #comments input[type="reset"]{color:inherit; background-color:#FBFBFB;}
tr:nth-child(even), #comments li:nth-child(even){color:inherit; background-color:#F7F7F7;}
table a, #comments a{background-color:inherit;}



/* Media Queries
--------------------------------------------------------------------------------------------------------------- */
@-ms-viewport{width:device-width;}


/* Max Wrapper Width - Laptop, Desktop etc.
--------------------------------------------------------------------------------------------------------------- */
@media screen and (min-width:978px){
	.hoc{max-width:978px;}
}


/* Mobile Devices
--------------------------------------------------------------------------------------------------------------- */
@media screen and (max-width:900px){
	.hoc{max-width:90%;}

	#header{padding:30px 0 15px;}
	#header #logo{margin:0;}
	#header{text-align:center;}


	.fl_right ul{display:none;}

	.mainlistvis  li{display:block; position:relative; margin:0 15px 0 0; padding:0;} 
	#mainav li{display:block; position:relative; margin:0 15px 0 0; padding:0;} 

	.menueicon {position : fixed;
		top : 36px;
		right : 34px;
	}

	#mainav form{display:block;}
	#mainav i{display: inline;}


	.menicon{visibility : visible;
		position : fixed;
		top : 36px;
		right : 34px;
	}

	#breadcrumb{}

	.container{}
	#comments input[type="reset"]{margin-top:10px;}
	.pagination li{display:inline-block; margin:0 5px 5px 0;}

	#footer{}

	#copyright{}
	#copyright p:first-of-type{margin-bottom:10px;}
	
	.plist{
		margin-left: 0;
	}
}


@media screen and (max-width:750px){
	.imgl, .imgr{display:flex; flex-wrap: wrap;flex:50%; margin:0 0 10px 0;}
	
	.one_half, .one_third, .two_third, .one_quarter, .two_quarter, .three_quarter{display : flex; flex-wrap : wrap; flex:50%; float:none; width:auto; margin:0 0 0px 0; padding:0;}
	
	
	.menicon{float:right;}
	#header #logo{margin-bottom:15px;}
	.plist{
		padding-left: 23%;
	}
	
	.font-x2{font-size:1.4rem;}
	.font-x3{font-size:1.6rem;}
	h6 {font-size:22px;}
	.infoboxes article{
		height : 220px;
		width : 190px;
	}
	.product{
		height : 150px;
		width : 170px;
		
	}
	
}


/* Other
--------------------------------------------------------------------------------------------------------------- */
@media screen and (max-width:650px){
	.scrollable{display:block; width:100%; margin:0 0 30px 0; padding:0 0 15px 0; overflow:auto; overflow-x:scroll;}
	.scrollable table{margin:0; padding:0; white-space:nowrap;}

	.inline li{display:block; margin-bottom:10px;}
	.pushright li{margin-right:0;}

	#pageintro{padding:150px 0;}
	#pageintro > div{max-width:none;}
	#pageintro .heading{font-size:2.2rem;}
}


/* #myVideo {
	position: fixed;
	right: 0px;
	bottom: 0px;
	min-width: 100%;
	min-height: 100%;
	height: 100vw;
	width: 20rw;
	transform: rotate(270deg);
  } */
.listbutton{
	text: center;
	
}
.formdiv{
	border-style: solid;
	border-color:#e0f2fe;
	padding:10px;
	background-color: #18181b;
}
.cartpic{
	text-align: center;
}
.qtypara{
	color:white;
	float: right;
	padding-right: 730px;
	margin-top:-2px;
	
}
.orderbtn{
	border-radius:10%;
	border:none;
	padding: 10px 15px;
	background-color: #0d9488;
	cursor: pointer;
}