@charset "utf-8";
/*
Template Name: Gomag
Author: <a href="http://www.os-templates.com/">OS Templates</a>
Author URI: http://www.os-templates.com/
Licence: Free to use under our free template licence terms
Licence URI: http://www.os-templates.com/template-terms
File: Framework CSS
*/

html{overflow-y:scroll; overflow-x:hidden;}
html, body{margin:0; padding:0; font-size:14px; line-height:1.6em;}

*, *::before, *::after{box-sizing:border-box;}

.bold{font-weight:bold;}
.center{text-align:center;}
.right{text-align:right;}
.uppercase{text-transform:uppercase;}
.capitalise{text-transform:capitalize;}
.hidden{display:none;}
.nospace{margin:0; padding:0; list-style:none;}
.block{display:block;}
.inline *{display:inline-block;}
.inline *:last-child{margin-right:0;}
.pushright li{margin-right:20px;}
.pushright li:last-child{margin-right:0;}
.borderedbox{border:1px solid;}
.overlay{position:relative; z-index:1;}
.overlay::after{display:block; position:absolute; top:0; left:0; width:100%; height:100%; content:""; z-index:-1;}
.bgded{
    
    background-position:top center; background-repeat:no-repeat; background-size:cover;}
.bgimg1{background-image:url('../public/images/demo/backgrounds/im1.jpg');}
.bgimg2{background-image:url('../public/images/demo/backgrounds/im2.jpg');}
.bgimg3{background-image:url('../public/images/demo/backgrounds/10.jpg');}
.circle{border-radius:50%; background-clip:padding-box;}

.btn{display:inline-block; padding:8px 18px 10px; text-transform:uppercase; border:1px solid;}

.clear, .group{display:block;}
.clear::before, .clear::after, .group::before, .group::after{display:table; content:"";}
.clear, .clear::after, .group, .group::after{clear:both;}

a{outline:none; text-decoration:none;}

.fl_left, .imgl{float:left;}
.fl_right, .imgr{float:right;}

img{width:auto; max-width:100%; height:auto; margin:0; padding:0; border:none; line-height:normal; vertical-align:middle;}
.imgl{margin:0 15px 10px 0; clear:left;}
.imgr{margin:0 0 10px 15px; clear:right;}


/* Fonts
--------------------------------------------------------------------------------------------------------------- */
body, input, textarea, select{font-family:Verdana, Geneva, sans-serif;}
h1, h2, h3, h4, h5, h6, .heading{font-family:Georgia, "Times New Roman", Times, serif;}


/* Forms
--------------------------------------------------------------------------------------------------------------- */
form, fieldset, legend{margin:0; padding:0; border:none;}
legend{display:none;}
label, input, textarea, select, button{display:block; resize:none; outline:none; color:inherit; font-size:inherit; font-family:inherit; vertical-align:middle;}
label{margin-bottom:5px;}
:required, :invalid{outline:none; box-shadow:none;}


/* Generalise
--------------------------------------------------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, .heading{margin:0 0 20px 0; font-size:22px; line-height:normal; font-weight:normal; text-transform:capitalize;}
.heading.nospace{margin-bottom:0;}

address{font-style:normal; font-weight:normal;}
hr{display:block; width:100%; height:1px; border:solid; border-width:1px 0 0 0;}

.font-xs{font-size:.8rem;}
.font-x1{font-size:1.2rem;}
.font-x2{font-size:1.8rem;}
.font-x3{font-size:2.8rem;}

.wrapper{display:block; position:relative; width:100%; margin:0; padding:0; text-align:left; word-wrap:break-word;}
/*
The "hoc" class is a generic class used to centre a containing element horizontally
It should be used in conjunction with a second class or ID
*/
.hoc{display:block; margin:0 auto;}


/* HTML 5 Overrides
--------------------------------------------------------------------------------------------------------------- */
address, article, aside, figcaption, figure, footer, header, main, nav, section{display:block; margin:0; padding:0;}


/* Grid
--------------------------------------------------------------------------------------------------------------- */
.one_half, .one_third, .two_third, .one_quarter, .two_quarter, .three_quarter{display:inline-block;  margin:0 0 0 3.06748466257669%; list-style:none;}

.first{margin-left:0; clear:left;}

.one_quarter{width:22.69938650306748%;}
.one_third{width:46.58834355828221%;}
.one_half, .two_quarter{width:48.46625766871166%;}
.two_third{width:65.6441717791411%;}
.three_quarter{width:74.23312883435584%;}

.branchsec{
    display:block;
    float:right;
    padding-left:30px;
    padding-top:80px;
}

/* Spacing
--------------------------------------------------------------------------------------------------------------- */
.btmspace-10{margin-bottom:10px;}
.btmspace-15{margin-bottom:15px;}
.btmspace-30{margin-bottom:30px;}
.btmspace-50{margin-bottom:50px;}
.btmspace-80{margin-bottom:80px;}

.inspace-5{padding:5px;}
.inspace-10{padding:10px;}
.inspace-15{padding:15px;}
.inspace-30{padding:30px;}
.inspace-50{padding:50px;}
.inspace-80{padding:80px;}

::-webkit-scrollbar-track {
    background: black; 
  }
   